import React from 'react'
import UserForm from '../../components/User/AddUserForm'
import MainContentContainer from '../../components/containers/MainContentContainer'

export default function UserFormPage () {
  return (
    <MainContentContainer
        title={ 'Dodavanje korisnika' }
        content={ <UserForm/> }
        />

  )
}
