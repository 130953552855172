/* eslint-disable brace-style */
import React, { useEffect, useState } from 'react'
import { MessageBar, MessageBarType, Pivot, PivotItem, Spinner, Stack } from '@fluentui/react'
import StyledButton from '../styledComponents/StyledButton'
import { useNavigate, useParams } from 'react-router-dom'
import { createPerpetrator, deletePerpetrator, editPerpetrator, getPerpetrator } from '../../assets/axios/services'
import { DropdownOptions } from '../../assets/consts/DropdownOptions'
import { PerpetratorStyle } from './Perpetrator.style'
import StyledTextField from '../styledComponents/StyledTextField'
import StyledDropdown from '../styledComponents/StyledDropdown'
import StyledCommentField from '../styledComponents/StyledCommentField'
import DropdownWithOptionalField from '../styledComponents/DropdownWithOptionalField'

export default function PerpetratorForm () {
  const [formData, setFormData] = useState<any>(null)
  const [message, setMessage] = useState({ type: MessageBarType.success, text: '' })
  const [actionInProgress, setActionInProgress] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [isEditable, setIsEditable] = useState(true)
  const navigate = useNavigate()

  const { id } = useParams()

  useEffect(() => {
    if (id !== undefined) {
      setIsEdit(true)
      setIsEditable(false)
      getPerpetrator(id).then((res) => {
        setFormData(res.data)
      })
    } else {
      setIsEdit(false)
      setIsEditable(true)
      setFormData(null)
    }
  }, [id])

  const onInput = (fieldName: string, value: string | number) => {
    setFormData({ ...formData, [fieldName]: value })
  }

  const onError = (err: any) => {
    setActionInProgress(false)
    setMessage({ type: MessageBarType.error, text: err.message })
  }

  const onSubmit = () => {
    setActionInProgress(true)
    if (isEdit) {
      editPerpetrator(id, formData).then(() => {
        setMessage({ type: MessageBarType.success, text: 'Unos uspiješno izmijenjen.' })
        setTimeout(() => {
          setIsEditable(false)
          setActionInProgress(false)
          setTimeout(() => {
            setMessage({ type: MessageBarType.success, text: '' })
          }, 1500)
        }, 1500)
      }).catch((err) => {
        onError(err)
      })
    } else {
      createPerpetrator(formData).then(() => {
        setMessage({ type: MessageBarType.success, text: 'Unos uspiješno kreiran.' })
        setTimeout(() => {
          navigate('/perpetrator')
        }, 1500)
      }).catch((err) => {
        onError(err)
      })
    }
  }

  const style = PerpetratorStyle()

  const canSubmit = () => {
    return formData?.first_name === undefined ||
      formData?.first_name.length === 0 ||
      formData?.last_name === undefined ||
      formData?.last_name.length === 0 ||
      actionInProgress
  }

  return (
    <Stack tokens={{ childrenGap: 50 }}>
      <Stack tokens={{ childrenGap: 15 }}>
        { message.text.length > 0
          ? <MessageBar
            onDismiss={ () => { setMessage({ ...message, text: '' }) } }
            messageBarType={ message.type }
            styles={ style.messageBar }>
            { message.text }
          </MessageBar>
          : <div style={{ height: '32px' }} />
        }
        <Pivot >
          <PivotItem headerText='Osnovni podaci'>
            <Stack horizontal style={{ maxWidth: '1000px' }} tokens={{ childrenGap: 20 }} wrap enableScopedSelectors>
              <StyledTextField
                disabled={ !isEditable }
                label='Ime'
                required
                name='first_name'
                value={ formData?.first_name }
                onChange={ (e: any, value: any) => { onInput(e.target.name, value) } }
              />
              <StyledTextField
                disabled={ !isEditable }
                required
                label='Prezime'
                name='last_name'
                value={ formData?.last_name }
                onChange={ (e: any, value: any) => { onInput(e.target.name, value) } }
              />
              <StyledTextField
                disabled={ !isEditable }
                label='Ime roditelja'
                name='parent_name'
                value={ formData?.parent_name }
                onChange={ (e: any, value: any) => { onInput(e.target.name, value) } }
              />
              <StyledTextField
                disabled={ !isEditable }
                label='Datum rođenja'
                name='birth_date'
                value={ formData?.birth_date }
                onChange={ (e: any, value: any) => { onInput(e.target.name, value) } }
              />
              <StyledTextField
                disabled={ !isEditable }
                label='Mjesto rođenja'
                name='birth_place'
                value={ formData?.birth_place }
                onChange={ (e: any, value: any) => { onInput(e.target.name, value) } }
              />
              <StyledDropdown
                options={ DropdownOptions.genderOptions }
                label='Pol'
                disabled={ !isEditable }
                selectedKey={ formData?.gender }
                onChange={ (e: any, option: any) => { onInput('gender', option.key) } }
              />
              <StyledTextField
                disabled={ !isEditable }
                label='JMB'
                name='jmb'
                value={ formData?.jmb }
                onChange={ (e: any, value: any) => { onInput(e.target.name, value) } }
              />
              <StyledTextField
                disabled={ !isEditable }
                label='Broj lične karte'
                name='identity_card'
                value={ formData?.identity_card }
                onChange={ (e: any, value: any) => { onInput(e.target.name, value) } }
              />
              <StyledTextField
                disabled={ !isEditable }
                label='Adresa'
                name='address'
                value={ formData?.address }
                onChange={ (e: any, value: any) => { onInput(e.target.name, value) } }
              />
              <StyledTextField
                disabled={ !isEditable }
                label='Državljanstvo'
                name='citizenship'
                value={ formData?.citizenship }
                onChange={ (e: any, value: any) => { onInput(e.target.name, value) } }
              />
              { /* <StyledTextField
            disabled={ !isEditable }
            label='Nacionalnost'
            name='nationality'
            value={ formData?.nationality }
            onChange={ (e: any, value: any) => { onInput(e.target.name, value) } }
          /> */ }

            </Stack>
          </PivotItem>
          <PivotItem headerText='Lični i sociodemografski podaci'>
            <Stack horizontal style={{ maxWidth: '1000px' }} tokens={{ childrenGap: 20 }} wrap enableScopedSelectors>
              <StyledDropdown
                disabled={ !isEditable }
                options={ DropdownOptions.workStatusOptions }
                label='Radni status'
                selectedKey={ formData?.working_status }
                onChange={ (e: any, option: any) => { onInput('working_status', option.key) } }
              />

              <StyledDropdown
                disabled={ !isEditable }
                options={ DropdownOptions.marriageOptions }
                label='Bračni status'
                selectedKey={ formData?.marriage_status }
                onChange={ (e: any, option: any) => { onInput('marriage_status', option.key) } }
              />
              <StyledDropdown
                disabled={ !isEditable }
                options={ DropdownOptions.educationOptions }
                label='Nivo obrazovanja'
                selectedKey={ formData?.education }
                onChange={ (e: any, option: any) => { onInput('education', option.key) } }
              />
              <StyledTextField
                disabled={ !isEditable }
                label='Lični mjesečni prihodi'
                value={ formData?.income }
                onChange={ (e: any, value: any) => { onInput(e.target.name, value) } }
                name='income'
              />
              <StyledTextField
                disabled={ !isEditable }
                label='Lična imovina'
                name='own_property'
                value={ formData?.own_property }
                onChange={ (e: any, value: any) => { onInput(e.target.name, value) } }
              />

              <StyledDropdown
                disabled={ !isEditable }
                label='Stambeni status'
                options={ DropdownOptions.residentialOptions }
                selectedKey={ formData?.residential_status }
                onChange={ (e: any, option: any) => { onInput('residential_status', option.key) } }
              />
              <DropdownWithOptionalField
                disabled={ !isEditable }
                options={ [...DropdownOptions.trueFalseOption, { text: 'Nepoznato', key: 'unknown' }] }
                label='Mentalno oboljenje'
                selectedKey={ formData?.mental_treatment }
                showTextFieldOption='yes'
                onChange={ (e: any, option: any) => { onInput('mental_treatment', option.key ?? option) } }
              />
              <StyledDropdown
                disabled={ !isEditable }
                options={ DropdownOptions.treatmentOptions }
                label='Da li je bio liječen'
                selectedKey={ formData?.treatment }
                onChange={ (e: any, option: any) => { onInput('treatment', option.key) } }
              />
              <DropdownWithOptionalField
                disabled={ !isEditable }
                options={ DropdownOptions.addictionOptions }
                label='Oblik zavisnosti'
                selectedKey={ formData?.addiction }
                showTextFieldOption='other'
                onChange={ (e: any, option: any) => { onInput('addiction', option.key ?? option) } }
              />

            </Stack>
          </PivotItem>
          <PivotItem headerText='Podaci o istoriji nasilja i preduzetim mjerama'>
            <Stack horizontal style={{ maxWidth: '1000px' }} tokens={{ childrenGap: 20 }} wrap enableScopedSelectors>
              <StyledDropdown
                disabled={ !isEditable }
                options={ DropdownOptions.trueFalseOption }
                label='Da li je ranije kažnjavan zbog nasilja'
                selectedKey={ formData?.previous_violence }
                onChange={ (e: any, option: any) => { onInput('previous_violence', option.key) } }
              />
              <DropdownWithOptionalField
                disabled={ !isEditable }
                options={ DropdownOptions.trueFalseOption }
                label='Da li je nasilan i prema drugim osobama'
                selectedKey={ formData?.violence_others }
                showTextFieldOption='yes'
                onChange={ (e: any, option: any) => { onInput('violence_others', option.key ?? option) } }
              />
              <StyledTextField
                disabled={ !isEditable }
                label='Mjere prije smještaja žrtve u sigurnu kuću'
                name='before_safehouse_measures'
                value={ formData?.before_safehouse_measures }
                onChange={ (e: any, value: any) => { onInput(e.target.name, value) } }
                multiline rows={ 4 }
              />

              <StyledCommentField
                disabled={ !isEditable }
                value={ formData?.notes }
                label='Komentar'
                multiline rows={ 4 }
                onChange={ (e: any, value: any) => { onInput(e.target.name, value) } }
                name='notes'
              />
              <StyledTextField
                disabled={ !isEditable }
                label='Mjere tokom boravka žrtve u sigurnoj kući'
                placeholder='Prijave nadležnim institucijama, izrečene mjere...'
                name='during_safehouse_measures'
                value={ formData?.during_safehouse_measures }
                onChange={ (e: any, value: any) => { onInput(e.target.name, value) } }
                multiline rows={ 4 }
              />
            </Stack>
          </PivotItem>
        </Pivot>
      </Stack>
      <Stack horizontal style={ style.row } tokens={{ childrenGap: 30 }} horizontalAlign='start'>
        <StyledButton
          disabled={ canSubmit() }
          onClick={ () => {
            !isEditable
              ? setIsEditable(true)
              : onSubmit()
          } }>
          { actionInProgress ? <Spinner /> : (isEditable ? 'Sačuvaj' : 'Izmjeni') }
        </StyledButton>
        <StyledButton
          red
          disabled={ (!isEditable || actionInProgress) || id === undefined }
          onClick={ () => {
            setActionInProgress(true)
            deletePerpetrator(id).then(() => {
              setMessage({ type: MessageBarType.success, text: 'Unos obrisan.' })
              setTimeout(() => {
                navigate('/perpetrator')
              }, 1500)
            }).catch(() => {
              setActionInProgress(false)
              setMessage({ type: MessageBarType.error, text: 'Greška. Pokušajte ponovo.' })
            })
          } }>
          { actionInProgress ? <Spinner /> : 'Obriši' }
        </StyledButton>
      </Stack>
    </Stack>
  )
}
