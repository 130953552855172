/* eslint-disable brace-style */
import React, { useState } from 'react'
import { MessageBar, MessageBarType, Pivot, PivotItem, Spinner, Stack } from '@fluentui/react'
import StyledButton from '../styledComponents/StyledButton'
import { useNavigate } from 'react-router-dom'
import { createNewUser } from '../../assets/axios/services'
import { UserStyle } from './User.style'
import StyledTextField from '../styledComponents/StyledTextField'

export default function AddUserForm () {
  const [formData, setFormData] = useState<any>({ role: 'user' })
  const [message, setMessage] = useState({ type: MessageBarType.success, text: '' })
  const [actionInProgress, setActionInProgress] = useState(false)
  const [password, setPassword] = useState('')
  const navigate = useNavigate()

  const onInput = (fieldName: string, value: string | number) => {
    setFormData({ ...formData, [fieldName]: value })
  }

  const onSubmit = () => {
    setActionInProgress(true)
    createNewUser(formData).then(() => {
      setMessage({ type: MessageBarType.success, text: 'Unos uspiješno kreiran.' })
      setTimeout(() => {
        navigate('/services')
      }, 1500)
    }).catch((err) => {
      setActionInProgress(false)
      setMessage({ type: MessageBarType.error, text: err.message })
    })
  }

  const validateAndSubmit = () => {
    if (formData?.password !== password) {
      setMessage({ type: MessageBarType.error, text: 'Lozinke se ne poklapaju.' })
    } else { onSubmit() }
  }

  const canSubmit = () => {
    return formData?.first_name === undefined ||
      formData?.first_name.length === 0 ||
      formData?.last_name === undefined ||
      formData?.last_name.length === 0 ||
      formData?.password === undefined ||
      formData?.password.length === 0 ||
      formData?.username === undefined ||
      formData?.username.length === 0 ||
      password.length === 0 ||
      actionInProgress
  }

  const style = UserStyle()

  return (
    <Stack tokens={{ childrenGap: 50 }}>
      <Stack tokens={{ childrenGap: 15 }}>
        { message.text.length > 0
          ? <MessageBar
          onDismiss={ () => { setMessage({ ...message, text: '' }) } }
          messageBarType={ message.type }
          styles={ style.messageBar }>
            { message.text }
          </MessageBar>
          : <div style={{ height: '32px' }}/>
        }
        <Pivot>
          <PivotItem headerText='Podaci o korisniku'>
            <Stack horizontal style={{ maxWidth: '1000px' }} tokens={{ childrenGap: 20 }} wrap enableScopedSelectors>
              <StyledTextField
                label='Ime'
                required
                name='first_name'
                value={ formData?.first_name }
                onChange={ (e: any, value: any) => { onInput(e.target.name, value) } }
              />
              <StyledTextField
                label='Prezime'
                required
                name='last_name'
                value={ formData?.last_name }
                onChange={ (e: any, value: any) => { onInput(e.target.name, value) } }
              />
              <StyledTextField
                label='Korisničko ime'
                required
                name='username'
                value={ formData?.username }
                onChange={ (e: any, value: any) => { onInput(e.target.name, value) } }
              />
              <StyledTextField
                label='Lozinka'
                required
                name='password'
                type='password'
                value={ formData?.password }
                onChange={ (e: any, value: any) => { onInput(e.target.name, value) } }
              />
              <StyledTextField
                disabled={
                  formData?.password === undefined ||
                  formData?.password?.length === 0
                }
                label='Lozinka ponovo'
                required
                type='password'
                value={ password }
                onChange={ (e: any, value: any) => { setPassword(value) } }
              />
            </Stack>
          </PivotItem>
        </Pivot>
      </Stack>
      <Stack horizontal style={ style.row } tokens={{ childrenGap: 30 }} horizontalAlign='start'>
        <StyledButton
          disabled={ canSubmit() }
          onClick={ () => { validateAndSubmit() } }>
          { actionInProgress ? <Spinner /> : 'Sačuvaj' }
        </StyledButton>
      </Stack>
    </Stack>
  )
}
