/* eslint-disable */

import axios from 'axios';

const instance = axios.create({
   baseURL: `${process.env.REACT_APP_API_BASE}`,
    //baseURL:'http://127.0.0.1:8000/api',
    responseType: 'json',
});

// Add a request interceptor
instance.interceptors.request.use((config) => {

    const TOKEN: any = JSON.parse(sessionStorage.getItem('token') ?? '{}')
     if (TOKEN) {
        config.headers!.Authorization = `Bearer ${TOKEN}`;
     }
    return config;
}, (error) =>
    Promise.reject(error));

instance.interceptors.response.use((response) => response, (error) => Promise.reject(error));

export default instance;
