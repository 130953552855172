import React from 'react'
import MainContentContainer from '../components/containers/MainContentContainer'
import ServiceCodesForm from '../components/serviceCodes/ServiceCodesForm'

export default function ServiceCodesPage () {
  return (
    <MainContentContainer
        title={ 'Liste tipova pomoći' }
        content={ <ServiceCodesForm/> }
        />

  )
}
