import React from 'react'
import { DefaultButton, Stack } from '@fluentui/react'
import SideNavigation from '../navigation/SideNavigation'
import TopNavigation from '../navigation/TopNavigation'
import { MainContentContainerStyle } from './MainContentContainer.style'
import { useNavigate } from 'react-router-dom'

export default function MainContentContainer ({ content, title, backButton }: any) {
  const style = MainContentContainerStyle()
  const navigate = useNavigate()
  return (
    <Stack>
      <TopNavigation/>
      <Stack horizontal>
        <SideNavigation/>
        <Stack style={ style.container } tokens={{ childrenGap: 10 }}>
          { backButton
            ? <DefaultButton text='Nazad'
          styles={ style.backButton }
          iconProps={{ iconName: 'chevronLeft' }}
          onClick={ () => { navigate(-1) } }
          />
            : <div style={{ height: '32px' }}/>
          }
          <span
          style={ style.title }
          >
            { title }
          </span>
          <Stack style={ style.content }>
            { content }
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  )
}
