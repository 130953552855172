/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { DefaultButton } from '@fluentui/react'
import React from 'react'
import { StyledComponentsStyle } from './StyledComponents.style'

export default function StyledButton ({ width, height, red, ...rest }: any) {
  const style = StyledComponentsStyle(width, height, red)

  return (
    <DefaultButton
    styles={ style.styledButton }
        { ...rest }
        />
  )
}
