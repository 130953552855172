import React from 'react'
import { Stack, DefaultButton } from '@fluentui/react'
import Logo from './logoLara.png'
import { useNavigate } from 'react-router-dom'
import { NavigationStyle } from './Navigation.style'
import { COLORS } from '../../assets/theme/colors'

export default function TopNavigation () {
  const userProfile = JSON.parse(sessionStorage.getItem('user_data') ?? '{}')

  const navigate = useNavigate()

  const style = NavigationStyle()

  return (
    <Stack horizontal horizontalAlign='space-between' verticalAlign='center' style={ style.topContainer }>
      <img
      src={ Logo }
       width={ 90 }
       height={ 50 }
       onClick={ () => { navigate('/services') } }
       style={{ cursor: 'pointer' }}
       />
      <DefaultButton
      styles={ style.topNavButton }
      text={ `${userProfile.first_name} ${userProfile.last_name}` }
      iconProps={{ iconName: 'Contact' }}
      menuProps={
   {
     styles: {
       container: {
         overflowY: 'hidden'
       }
     },
     items: [
       //  {
       //    key: 'lock',
       //    text: 'Zaključaj ekran',
       //    iconProps: { iconName: 'Lock' },
       //    onClick: () => {
       //      navigate('/lock')
       //    }
       //  },
       {
         key: 'logout',
         text: 'Odjava',
         iconProps: {
           iconName: 'SignOut',
           style: {
             color: COLORS.primary
           }
         },
         onClick: () => {
           sessionStorage.removeItem('token')
           sessionStorage.removeItem('user_data')
           navigate('/login')
         }
       }
     ]
   }
    }
      />
    </Stack>
  )
}
