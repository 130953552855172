import { COLORS } from '../../assets/theme/colors'

export const StyledComponentsStyle = (width?: any, height?: any, red?: boolean) => ({
  row: {
    width: '100%'
  },
  headerField: {
    width: '100%',
    height: '100%'
  },
  noData: {
    padding: '0 5px',
    width: '100%',
    height: '50px'
  },
  noDataText: {
    color: COLORS.darkerGray,
    fontSize: '12px'
  },
  tooShort: {
    color: COLORS.darkerGray,
    fontSize: '12px'
  },
  textField: {
    root: {
      width: '300px',
      // marginRight: '20px',
      // marginBottom: '20px',
      '.ms-Label': {
        color: COLORS.darkerGray,
        textAlign: 'left' as const,
        paddingLeft: '5px'
      }
    }
  },
  commentField: {
    root: {
      width: '620px',
      '.ms-Label': {
        color: COLORS.darkerGray,
        textAlign: 'left' as const,
        paddingLeft: '5px'
      }
    }
  },
  searchableDropdown: {
    root: {
      // marginRight: '20px',
      // marginBottom: '20px',
      width: '300px'
    },
    title: {
      textAlign: 'left'
    },
    label: {
      width: '100%',
      color: COLORS.darkerGray,
      textAlign: 'left' as const,
      paddingLeft: '5px'
    },
    callout: {
      maxHeight: 'none !important',
      selectors: {
        '.ms-Callout-main': {
          maxHeight: '360px !important'
        }
      }
    },
    dropdownItemHeader: {
      height: '60px'
    }
  },
  dropdown: {
    root: {
      // marginRight: '20px',
      // marginBottom: '20px',
      width: '300px'
    },
    title: {
      textAlign: 'left'
    },
    label: {
      width: '100%',
      color: COLORS.darkerGray,
      textAlign: 'left' as const,
      paddingLeft: '5px'
    },
    callout: {
      maxHeight: 'none !important',
      selectors: {
        '.ms-Callout-main': {
          maxHeight: '360px !important'
        }
      }
    }
  },
  styledButton: {
    root: {
      backgroundColor: red ? '#cf4444' : COLORS.primary,
      borderRadius: '5px',
      width: width ?? '140px',
      height: height ?? '40px',
      color: 'white'
    },
    rootHovered: {
      backgroundColor: red ? '#b32222' : COLORS.darker,
      color: 'white'
    },
    rootPressed: {
      backgroundColor: red ? '#f50505' : COLORS.teal,
      color: 'white'
    },
    label: {
      fontWeight: '200'
    }
  },
  datePicker: {
    textField: {
      textAlign: 'left',
      // marginRight: '20px',
      // marginBottom: '20px',
      width: '300px',
      '.ms-Label': {
        textAlign: 'left',
        paddingLeft: '5px',
        width: '100%',
        color: COLORS.darkerGray
      }
    }
  }
})
