import { COLORS } from '../../assets/theme/colors'

export const ServiceTypeStyles = (hover?: boolean, clicked?: boolean) => ({
  cardContainer: {
    userSelect: 'none' as const,
    width: 'calc(50% - 30px)',
    minwidth: '300px',
    height: '200px',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    color: 'white',
    padding: '15px',
    cursor: 'pointer',
    borderBottom: `${clicked ? `3px solid ${COLORS.teal}` : (hover ? `3px solid ${COLORS.primary}` : 'none')}`,
    boxShadow: `4px 4px 2px -2px ${COLORS.darkerGray}`
  },
  upperLabel: {
    fontSize: '45px',
    fontWeight: 'bold'
  },
  lowerStack: {
    width: '100%'
  },
  lowerLabel: {
    fontSize: '21px'
  },
  icon: {
    fontSize: '35px'
  },
  container: {
    width: '100%'
  },
  row: {
    width: '100%',
    minHeight: '200px'
  },
  card: {
    width: '50%',
    height: '100%',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100%',
    color: 'white',
    padding: '15px'
  }
})
