/* eslint-disable no-console */
import React from 'react'
import { DefaultButton, Stack } from '@fluentui/react'
import { useLocation, useNavigate } from 'react-router-dom'
import { NavigationStyle } from './Navigation.style'

export default function SideNavigationButtonStack ({ buttons }: any) {
  const navigate = useNavigate()

  const style = NavigationStyle()

  return (
    <Stack style={ style.buttonStack }>
      {
            buttons.map((button: any, index: any) => (
              <DefaultButton
                key={ `nav-button-${button.label}-${index}` }
                iconProps={{ iconName: button.iconName }}
                styles={ NavigationStyle(useLocation().pathname === button.link).sideButton }
                text={ button.label }
                onClick={ () => { navigate(button.link) } }
                />
            ))
        }

    </Stack>
  )
}
