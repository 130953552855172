import { MessageBar, MessageBarType, Spinner, Stack } from '@fluentui/react'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { createProperties, getProperties } from '../../assets/axios/services'
import StyledButton from '../styledComponents/StyledButton'
import StyledDropdown from '../styledComponents/StyledDropdown'
import StyledTextField from '../styledComponents/StyledTextField'
import { SettingsStyle } from './Settings.style'

export default function SettingsForm () {
  const style = SettingsStyle()
  const [isEditable, setIseditable] = useState(false)
  const [formData, setFormData] = useState<any>({})
  const [actionInProgress, setActionInProgress] = useState(false)
  const [message, setMessage] = useState({ type: MessageBarType.success, text: '' })
  const navigate = useNavigate()

  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const values: any = {}
    getProperties('location').then((res) => {
      values.location = res.data.value
      getProperties('version').then((res) => {
        setFormData({ ...values, version: res.data.value })
        setLoading(false)
      })
    }).catch((err) => {
      if (err.response.request.status === 404) { setIseditable(true) }
      setLoading(false)
    })
  }, [])

  const canSubmit = () => {
    return !isEditable ||
    actionInProgress ||
     formData?.version === undefined ||
     formData?.version?.length === 0 ||
     formData?.location === undefined ||
     formData?.location?.length === 0
  }

  const onSubmit = () => {
    setActionInProgress(true)

    createProperties({ key: 'location', value: formData.location }).then(() => {
      createProperties({ key: 'version', value: formData.version }).then(() => {
        createProperties({ key: 'date', value: moment().format('DD.MM.YYYY') }).then(() => {
          setTimeout(() => {
            setActionInProgress(false)
            setMessage({ type: MessageBarType.success, text: 'Podešavanja uspiješno završena.' })
            setTimeout(() => {
              navigate('/about')
            }, 1500)
          }, 1500)
        }).catch((err) => {
          setActionInProgress(false)
          setMessage({ type: MessageBarType.error, text: err.message })
        })
      }).catch((err) => {
        setActionInProgress(false)
        setMessage({ type: MessageBarType.error, text: err.message })
      })
    }).catch((err) => {
      setActionInProgress(false)
      setMessage({ type: MessageBarType.error, text: err.message })
    })
  }

  return (
    <Stack tokens={{ childrenGap: 50 }}>
      <Stack tokens={{ childrenGap: 15 }}>
        { message.text.length > 0
          ? <MessageBar
          onDismiss={ () => { setMessage({ ...message, text: '' }) } }
          messageBarType={ message.type }
          styles={ style.messageBar }>
            { message.text }
          </MessageBar>
          : <div style={{ height: '32px' }}/>
      }
        { loading
          ? <Stack
        verticalAlign='center'
        style={{ height: '81px', width: '730px' }}>
            <Spinner/>
          </Stack>
          : <Stack horizontal style={{ maxWidth: '1000px' }} tokens={{ childrenGap: 20 }} wrap enableScopedSelectors>
            <StyledTextField
            label='Lokacija'
            disabled={ !isEditable }
            value={ formData?.location }
            onChange={ (e: any, value: any) => { setFormData({ ...formData, location: value }) } }
          />
            <StyledDropdown
            label='Verzija'
            options = { [{ text: '1.2.0', key: '1.2.0' }] }
            disabled={ !isEditable }
            selectedKey={ formData?.version }
         onChange={ (e: any, option: any) => { setFormData({ ...formData, version: option.key }) } }
          />

          </Stack> }
      </Stack>
      <Stack horizontal horizontalAlign='start' >
        <StyledButton
         disabled={ canSubmit() }
           onClick={ () => {
             onSubmit()
           } }
          >
          { actionInProgress ? <Spinner/> : 'Sačuvaj' }
        </StyledButton>
      </Stack>
    </Stack>
  )
}
