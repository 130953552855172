import { COLORS } from '../../assets/theme/colors'

export const SafeHouseStyle = () => ({
  label: {
    width: '100%',
    paddingLeft: '5px',
    textAlign: 'left' as const,
    color: COLORS.darkerGray
  },
  labeledText: {
    height: '32px',
    width: '300px',
    textAlign: 'left' as const,
    paddingLeft: '10px',
    lineHeight: '32px'
  },
  messageBar: {
    root: {
      width: '100%',
      maxWidth: '940px'
    }
  },
  datePicker: {
    marginBottom: '-5px',
    width: '300px'
  },
  row: {
    width: '100%'
  },
  topRow: {
    width: '100%',
    paddingRight: '8px'
  },
  helpContainer: {
    width: '730px',
    textAlign: 'left' as const,
    border: `1px solid ${COLORS.lightGray}`,
    padding: '5px 0'
  },
  helpLabel: {
    width: '340px',
    padding: '0 5px'
  }
})
