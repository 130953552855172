import React from 'react'
import { useParams } from 'react-router-dom'
import AdvisoryHelpForm from '../../components/advisoryHelp/AdvisoryHelpForm'
import MainContentContainer from '../../components/containers/MainContentContainer'

export default function AdvisoryHelpFormPage () {
  const { id } = useParams()
  return (
    <MainContentContainer
    backButton
        title={ `${id === undefined ? 'Dodaj' : 'Izmjeni'} unos za uslugu - Savjetodavna pomoć` }
        content={ <AdvisoryHelpForm/> }
        />
  )
}
