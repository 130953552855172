import React from 'react'
import { Stack } from '@fluentui/react'
import SideNavigationButtonStack from './SideNavigationButtonStack'
import { Links } from '../../assets/consts/NavigationLinks'
import { NavigationStyle } from './Navigation.style'

export default function SideNavigation () {
  const style = NavigationStyle()

  return (
    <Stack style={ style.sideContainer }>
      <SideNavigationButtonStack
      buttons={ Links.addServicesButtons }
      />
      <SideNavigationButtonStack
      buttons={ Links.addUsersButtons }
      />
      <SideNavigationButtonStack
      buttons={ Links.servicesOverviewButtons }
      />
      <SideNavigationButtonStack
      buttons={ Links.settingsButtons }
      />
      <SideNavigationButtonStack
      buttons={ Links.reportButtons }
      />
      <SideNavigationButtonStack
      buttons={ Links.infoButtons }
      />
    </Stack>
  )
}
