import { COLORS } from '../../assets/theme/colors'

export const UserStyle = () => ({
  textField: {
    root: {
      width: '300px',
      '.ms-Label': {
        color: COLORS.darkerGray,
        textAlign: 'left' as const,
        paddingLeft: '5px'
      }
    }
  },
  messageBar: {
    root: {
      width: '100%',
      maxWidth: '940px'
    }
  },
  row: {
    width: '100%'
  },
  topRow: {
    width: '100%',
    paddingRight: '8px'
  }
}
)
