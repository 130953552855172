export const Links = {
  addServicesButtons: [
    {
      iconName: 'home',
      label: 'Vrste usluga',
      link: '/services'
    }
  ],
  addUsersButtons: [
    {
      iconName: 'Contact',
      label: 'Unos žrtve nasilja',
      link: '/victim/new'
    },
    {
      iconName: 'Contact',
      label: 'Unos počinioca nasilja',
      link: '/perpetrator/new'
    },
    {
      iconName: 'Contact',
      label: 'Unos korisnika',
      link: '/user/new'
    }

  ],
  servicesOverviewButtons: [
    {
      iconName: 'BulletedList',
      label: 'Pregled žrtava',
      link: '/victim'
    },
    {
      iconName: 'BulletedList',
      label: 'Pregled počinioca',
      link: '/perpetrator'
    },
    {
      iconName: 'BulletedList',
      label: 'Pregled korisnika',
      link: '/user'
    }
  ],
  settingsButtons: [
    {
      iconName: 'MapLayers',
      label: 'Šifrarnici',
      link: '/bookcodes'
    },
    {
      iconName: 'Settings',
      label: 'Podešavanja',
      link: '/settings'
    }
  ],
  reportButtons: [
    {
      iconName: 'ReportDocument',
      label: 'Izvještaj',
      link: '/reports'
    }
  ],
  infoButtons: [
    {
      iconName: 'Info',
      label: 'O aplikaciji',
      link: '/about'
    }
  ]
}
