import { DropdownMenuItemType } from '@fluentui/react'

export const DropdownOptions = {

  genderOptions: [
    {
      text: 'Muški',
      key: 'male'
    },
    {
      text: 'Ženski',
      key: 'female'
    }
  ],

  institutionOptions: [
    {
      text: 'Nije se obraćala institucijama',
      key: 'none'
    },
    {
      text: 'Policija',
      key: 'police'
    },
    {
      text: 'Centar za socijalni rad',
      key: 'social'
    },
    {
      text: 'Sud',
      key: 'court'
    },
    {
      text: 'Tužilaštvo',
      key: 'proseccutor'
    },
    {
      text: 'Zdravstvena ustanova',
      key: 'medical'
    },
    {
      text: 'Služba za besplatnu pravnu pomoć',
      key: 'legal'
    },
    {
      text: 'NVO',
      key: 'nvo'
    },
    {
      text: 'Drugo',
      key: 'other'
    },
    {
      text: '-',
      key: '-'
    }
  ],

  trueFalseOption: [
    {
      text: 'Da',
      key: 'yes'
    },
    {
      text: 'Ne',
      key: 'no'
    }
  ],

  marriageOptions: [
    {
      text: 'Udata/Oženjen',
      key: 'married'
    },
    {
      text: 'Neudata/Neoženjen',
      key: 'single'
    },
    {
      text: 'Razveden/a',
      key: 'divorced'
    },
    {
      text: 'Udovica/Udovac',
      key: 'widow'
    }
  ],

  workStatusOptions: [
    {
      text: 'Zaposlenje',
      key: 'work',
      itemType: DropdownMenuItemType.Header
    },
    {
      text: 'Nije zaposlen/a',
      key: 'unemployed'
    },
    {
      text: 'Stalno zaposlenje',
      key: 'full_employed'
    },
    {
      text: 'Zaposlenje na određeno',
      key: 'employed'
    },
    {
      text: 'Privremeni i povremeni poslovi',
      key: 'temporary_employed'
    },
    {
      text: 'Rad na crno',
      key: 'illegal_employed'
    },
    {
      text: 'U penziji',
      key: 'retired'
    },
    {
      text: 'Školovanje',
      key: 'study',
      itemType: DropdownMenuItemType.Header
    },
    {
      text: 'Student/ica',
      key: 'student'
    },
    {
      text: 'Učenik/ca osnovne škole',
      key: 'primary_school_student'
    },
    {
      text: 'Učenik/ca srednje škole',
      key: 'secondary_school_student'
    },
    {
      text: 'Drugo',
      key: 'other_options',
      itemType: DropdownMenuItemType.Header
    },
    {
      text: 'Drugo',
      key: 'other'
    }
  ],

  victimType: [
    {
      text: 'Žena',
      key: 'woman'
    },
    {
      text: 'Dijete u pratnji majke',
      key: 'child_with_mother'
    },
    {
      text: 'Dijete bez pratnje',
      key: 'child'
    },
    {
      text: 'Žena sa djetetom',
      key: 'woman_with_child'
    }
  ],

  residentialOptions: [
    {
      text: 'Vlastita kuća/stan',
      key: 'own_home'
    },
    {
      text: 'Podstanar',
      key: 'tenant'
    },
    {
      text: 'Privremeni smještaj',
      key: 'temporary_residence'
    },
    {
      text: 'Živi u zajedničkom domaćinstvu',
      key: 'shared_household'
    },
    {
      text: 'Drugo',
      key: 'other'
    },
    {
      text: '-',
      key: '-'
    }
  ],

  ageOptions: [
    {
      text: '0-15',
      key: '15'
    },
    {
      text: '15-18',
      key: '18'
    },
    {
      text: '18-35',
      key: '35'
    },
    {
      text: '35-60',
      key: '60'
    },
    {
      text: 'preko 60',
      key: 'over_60'
    },
    {
      key: '-',
      text: '-'
    }
  ],

  violenceCauseOptions: [
    {
      text: 'Alkohol',
      key: 'alcohol'
    },
    {
      text: 'Droga',
      key: 'drugs'
    },
    {
      text: 'Finansijski problemi',
      key: 'financial_issues'
    },
    {
      text: 'Ljubomora',
      key: 'jealousy'
    },
    {
      text: 'PTSP',
      key: 'PTSD'
    },
    {
      text: 'Pritisci na poslu',
      key: 'job_issues'
    },
    {
      text: 'Drugo',
      key: 'other'
    },
    {
      text: '-',
      key: '-'
    }
  ],

  violenceConsequenceOptions: [

    {
      text: 'Bez posljedica',
      key: 'no_consequences'
    },
    {
      text: 'Lakše fizičke povrede',
      key: 'light_injuries'
    },
    {
      text: 'Teže fizičke povrede',
      key: 'heavy_injuries'
    },
    {
      text: 'Psihičke patnje',
      key: 'psychological_issues'
    },
    {
      text: 'Oboljenja',
      key: 'illness'
    },
    {
      text: 'Osjećanje straha',
      key: 'fear'
    },
    {
      text: 'Narušeno zdravlje',
      key: 'health_issues'
    },
    {
      text: 'Drugo',
      key: 'other'
    },
    {
      text: '-',
      key: '-'
    }
  ],

  addictionOptions: [
    {
      text: 'Nema zavisnost',
      key: 'no'
    },
    {
      text: 'Alkoholizam',
      key: 'alcoholism'
    },
    {
      text: 'Narkomanija',
      key: 'drugs'
    },
    {
      text: 'Kocka',
      key: 'gambling'
    },
    {
      text: 'Drugo',
      key: 'other'
    }
  ],

  methodOptions: [
    {
      text: 'Po zahtijevu policije',
      key: 'police_request'
    },
    {
      text: 'Po zahtjevu centra za socijalni rad / službe socijalne zaštite',
      key: 'social_work_center'
    },
    {
      text: 'Na lični zahtijev',
      key: 'personal_request'
    },
    {
      text: 'Ostalo',
      key: 'other'
    },
    {
      text: '-',
      key: '-'
    }
  ],

  decisionByOptions: [
    {
      text: 'Korisnica',
      key: 'by_user'
    },
    {
      text: 'Centar za socijalni rad',
      key: 'by_social_work_center'
    },
    {
      text: 'Stručni tim',
      key: 'by_expert_team'
    },
    {
      text: 'Ostalo',
      key: 'other'
    },
    {
      text: '-',
      key: '-'
    }
  ],

  statusOptions: [
    {
      text: 'Povratak u domaćinstvo sa počiniocem nasilja',
      key: 'back_with_perpetrator'
    },
    {
      text: 'Napuštanje nasilne zajednice i povratak u primarnu porodicu',
      key: 'leaving_and_back_to_home'
    },
    {
      text: 'Podstanarski status',
      key: 'tenant'
    },
    {
      text: 'Odlazak kod srodnika',
      key: 'relative'
    },
    {
      text: 'Odlazak u drugu ustanovu / sklonište',
      key: 'other_institution'
    },
    {
      text: 'Odlazak u drugu zemlju',
      key: 'other_country'
    },
    {
      text: 'Socijalni stan',
      key: 'social_flat'
    },
    {
      text: 'Drugo',
      key: 'other'
    },
    {
      text: '-',
      key: '-'
    }
  ],

  childrenEducationOption: [
    {
      text: 'Osnovna škola',
      key: 'primary_school'
    },
    {
      text: 'Srednja škola',
      key: 'secondary_school'
    }
  ],

  riskAssesmentOptions: [
    {
      text: 'Nizak',
      key: 'low'
    },
    {
      text: 'Umjeren',
      key: 'moderate'
    },
    {
      text: 'Visok',
      key: 'high'
    },
    {
      text: 'Nepoznato',
      key: 'unknown'
    },
    {
      text: '-',
      key: '-'
    }
  ],

  perpetratorRelationOptions: [
    {
      text: 'Suprug',
      key: 'spouse'
    },
    {
      text: 'Partner u vanbračnoj zajednici',
      key: 'non_marital_partner'
    },
    {
      text: 'Momak',
      key: 'boyfriend'
    },
    {
      text: 'Bivši suprug',
      key: 'ex_spouse'
    },
    {
      text: 'Otac / majka',
      key: 'parent'
    },
    {
      text: 'Očuh / maćeha',
      key: 'step_parent'
    },
    {
      text: 'Sin / kćerka',
      key: 'child'
    },
    {
      text: 'Brat / sestra',
      key: 'sibling'
    },
    {
      text: 'Nepoznato',
      key: 'unknown'
    },
    {
      text: 'Drugo',
      key: 'other'
    }
  ],

  educationOptions: [
    {
      text: 'Bez formalnog obrazovanja',
      key: 'no_education'
    },
    {
      text: 'Osnovna škola',
      key: 'elementary_school'
    },
    {
      text: 'Srednja škola',
      key: 'secondary_school'
    },
    {
      text: 'Viša škola',
      key: 'higher_school'
    },
    {
      text: 'Fakultet',
      key: 'faculty'
    },
    {
      text: 'Magisterij',
      key: 'magistry'
    },
    {
      text: 'Doktorat',
      key: 'phd'
    },
    {
      text: 'Nepoznato',
      key: 'unknown'
    }
  ],

  violenceTypeOptions: [
    {
      text: 'Fizičko',
      key: 'physical'
    },
    {
      text: 'Psihičko',
      key: 'psychological'
    },
    {
      text: 'Ekonomsko',
      key: 'economic'
    },
    {
      text: 'Seksualno',
      key: 'sexual'
    },
    {
      text: 'Kombinovano',
      key: 'combined'
    },
    {
      text: 'Nepoznato',
      key: 'unknown'
    }
  ],
  violenceDurationOptions: [
    {
      text: '0-1 godinu',
      key: '0_to_1'
    },
    {
      text: '2-3 godine',
      key: '2_to_3'
    },
    {
      text: '4-5 godina',
      key: '4_to_5'
    },
    {
      text: '6-10 godina',
      key: '6_to_10'
    },
    {
      text: 'Više od 10 godina',
      key: 'over_10'
    },
    {
      text: 'Nepoznato',
      key: 'unknown'
    }
  ],

  treatmentOptions: [
    {
      text: 'Ambulantno liječenje',
      key: 'ambulance'
    },
    {
      text: 'Hospitalizacija',
      key: 'hospital'
    },
    {
      text: 'Nepoznato',
      key: 'unknown'
    },
    {
      text: '-',
      key: '-'
    }
  ],

  institutionHelpOptions: [
    {
      text: 'Centar za socijalni rad',
      key: 'social'
    },
    {
      text: 'Policija',
      key: 'police'
    },
    {
      text: 'Pravosudne institucije',
      key: 'court'
    },
    {
      text: 'Drugo',
      key: 'other'
    },
    {
      text: '-',
      key: '-'
    }
  ],

  phaseOptions: [
    {
      text: 'Faza prijave nasilja',
      key: 'report'
    },
    {
      text: 'Faza istražnog postupka',
      key: 'investigation'
    },
    {
      text: 'Faza sudskog postupka',
      key: 'trial'
    },
    {
      text: 'Drugo',
      key: 'other'
    },
    {
      text: '-',
      key: '-'
    }
  ]
}
