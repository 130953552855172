import React from 'react'
import MainContentContainer from '../components/containers/MainContentContainer'
import SettingsView from '../components/settings/SettingsView'

export default function SettingsViewPage () {
  return (
    <MainContentContainer
        title={ 'O aplikaciji' }
        content={ <SettingsView/> }
        />

  )
}
