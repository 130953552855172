import React from 'react'
import { useParams } from 'react-router-dom'
import MainContentContainer from '../../components/containers/MainContentContainer'
import SafeHouseForm from '../../components/safeHouse/SafeHouseForm'

export default function SafeHouseFormPage () {
  const { id } = useParams()
  return (
    <MainContentContainer
        backButton
        title={ `${id === undefined ? 'Dodaj' : 'Izmjeni'} unos za uslugu - Sigurna kuća` }
        content={ <SafeHouseForm/> }
        />
  )
}
