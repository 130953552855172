import React from 'react'
import MainContentContainer from '../components/containers/MainContentContainer'
import SettingsForm from '../components/settings/SettingsForm'

export default function SettingsFormPage () {
  return (
    <MainContentContainer
        title={ 'Podešavanja' }
        content={ <SettingsForm/> }
        />

  )
}
