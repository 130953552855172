/* eslint-disable comma-dangle */
/* eslint-disable no-trailing-spaces */
/* eslint-disable brace-style */
import React, { useEffect, useState } from 'react'
import { Label, MessageBar, MessageBarType, Spinner, Stack } from '@fluentui/react'
import SearchableDropdown from '../styledComponents/SearchableDropdown'
import StyledButton from '../styledComponents/StyledButton'
import { useNavigate, useParams } from 'react-router-dom'
import {
  createAvisoryHelpEntry, deleteAdvisoryHelpEntry, editAdvisoryHelpEntry,
  getAdvisoryDataTypes, getAdvisoryHelpEntry
} from '../../assets/axios/services'
import { AdvisoryHelpStyle } from './AdvisoryHelp.style'
import StyledDropdown from '../styledComponents/StyledDropdown'
import moment from 'moment'
import StyledDatePicker from '../styledComponents/StyledDatePicker'
import StyledCommentField from '../styledComponents/StyledCommentField'

export default function AdvisoryHelpForm () {
  const [formData, setFormData] = useState<any>(null)
  const [message, setMessage] = useState({ type: MessageBarType.success, text: '' })
  const [actionInProgress, setActionInProgress] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [isEditable, setIsEditable] = useState(true)
  const [dropdownData, setDropdownData] = useState([])

  const style = AdvisoryHelpStyle()
  const navigate = useNavigate()

  const { id } = useParams()

  useEffect(() => {
    if (id !== undefined) {
      setIsEdit(true)
      setIsEditable(false)
      getAdvisoryHelpEntry(id).then((res) => {
        setFormData(res.data)
      })
    }
    getAdvisoryDataTypes().then((res: any) => {
      setDropdownData(generateOptions(res.data.items))
    })
  }, [])

  const generateOptions = (data: any) => {
    return data.map((item: any) => ({
      text: item.type,
      key: item.type
    }))
  }

  const onInput = (fieldName: string, value: string | number) => {
    setFormData({ ...formData, [fieldName]: value })
  }

  const onError = (err: any) => {
    setActionInProgress(false)
    setMessage({ type: MessageBarType.error, text: err.message })
  }

  const onSubmit = () => {
    setActionInProgress(true)
    if (isEdit) {
      editAdvisoryHelpEntry(id, formData).then(() => {
        setMessage({ type: MessageBarType.success, text: 'Unos uspiješno izmijenjen.' })
        setTimeout(() => {
          setIsEditable(false)
          setActionInProgress(false)
          setTimeout(() => {
            setMessage({ type: MessageBarType.success, text: '' })
          }, 1500)
        }, 1500)
      }).catch((err) => {
        onError(err)
      })
    } else {
      createAvisoryHelpEntry(formData).then(() => {
        setMessage({ type: MessageBarType.success, text: 'Unos uspiješno kreiran.' })
        setTimeout(() => {
          navigate('/services/advisoryhelp')
        }, 1500)
      }).catch((err) => {
        onError(err)
      })
    }
  }

  return (
    <Stack tokens={{ childrenGap: 50 }}>
      <Stack tokens={{ childrenGap: 15 }}>
        { message.text.length > 0
          ? <MessageBar
          onDismiss={ () => { setMessage({ ...message, text: '' }) } }
          messageBarType={ message.type }
          styles={ style.messageBar }>
            { message.text }
          </MessageBar>
          : <div style={{ height: '32px' }}/>
        }
        <Stack horizontal style={{ maxWidth: '1000px' }} tokens={{ childrenGap: 20 }} wrap enableScopedSelectors>
          { isEdit
            ? <Stack style={{ width: '300px' }}>
              <Label style={ style.label }>Žrtva nasilja</Label>
              <span style={ style.labeledText }>
                { `${formData?.victim_first_name} ${formData?.victim_last_name}` }
              </span>
            </Stack>
            : <SearchableDropdown
            required
            label='Žrtva nasilja'
            searchBy='victim'
            onChange={ (e: any, value: any) => { onInput('victim_id', value.key) } }
          /> }

          { isEdit
            ? <Stack style={{ width: '300px' }}>
              <Label style={ style.label }>Počinilac nasilja</Label>
              <span style={ style.labeledText }>
                { `${formData?.perpetrator_first_name ?? ''} ${formData?.perpetrator_last_name ?? ''}` }
              </span>
            </Stack>
            : <SearchableDropdown
            label='Počinilac nasilja'
            searchBy='perpetrator'
            onChange={ (e: any, value: any) => { onInput('perpetrator_id', value.key) } }
          /> }
          <StyledDropdown
          required
          options={ dropdownData }
          disabled={ !isEditable }
          selectedKey={ formData?.type }
          label='Tip pomoći'
          onChange={ (e: any, value: any) => { onInput('type', value.key) } }
        />

          <StyledCommentField
                disabled={ !isEditable }
                multiline
                rows={ 4 }
                label='Opis pružene pomoći'
                name='notes'
                value={ formData?.notes }
                onChange={ (e: any, value: any) => { onInput(e.target.name, value) } }
              />
          <StyledDatePicker
                disabled={ !isEditable }
                value={ formData?.date !== undefined && formData.date !== null
                  ? new Date(moment(formData.date, 'DD.MM.YYYY').format())
                  : undefined }
                label='Datum pružanja pomoći'
                onSelectDate={ (date: any) => { onInput('date', moment(date).format('DD.MM.YYYY')) } }
              />
        </Stack>
      </Stack>
      <Stack horizontal horizontalAlign='start' tokens={{ childrenGap: 30 }}>
        <StyledButton
          disabled={
            formData?.victim_id === undefined ||
            formData?.type === undefined ||
            actionInProgress
          }
          onClick={ () => {
            !isEditable
              ? setIsEditable(true)
              : onSubmit()
          } }>
          { actionInProgress ? <Spinner /> : (isEditable ? 'Sačuvaj' : 'Izmjeni') }
        </StyledButton>
        <StyledButton
          red
          disabled={ (!isEditable || actionInProgress) || id === undefined }
          onClick={ () => {
            setActionInProgress(true)
            deleteAdvisoryHelpEntry(id).then(() => {
              setMessage({ type: MessageBarType.success, text: 'Unos obrisan.' })
              setTimeout(() => {
                navigate('/services/advisoryhelp')
              }, 1500)
            }).catch(() => {
              setActionInProgress(false)
              setMessage({ type: MessageBarType.error, text: 'Greška. Pokušajte ponovo.' })
            })
          } }>
          { actionInProgress ? <Spinner /> : 'Obriši' }
        </StyledButton>
      </Stack>
    </Stack>
  )
}
