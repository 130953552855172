import React from 'react'
import MainContentContainer from '../../components/containers/MainContentContainer'
import SafeHouseList from '../../components/safeHouse/SafeHouseList'

export default function SafeHouseListView () {
  return (
    <MainContentContainer
        title={ 'Lista unosa za uslugu - Sigurna kuća' }
        content={ <SafeHouseList/> }
        />

  )
}
