export const Columns = (onSort, sortedAsc, sortedBy) => ({

  AdvisoryHelpColumns: [
    {
      key: 'victim',
      name: 'Žrtva nasilja',
      fieldName: 'victim',
      minWidth: 200,
      maxWidth: 200,
      onColumnClick: () => onSort('victim_last_name'),
      isSortedDescending: sortedAsc,
      isSorted: sortedBy === 'victim_last_name',
      styles: {
        root: {
          cursor: 'pointer'
        }
      }
    },
    {
      key: 'perpetrator',
      name: 'Počinilac nasilja',
      fieldName: 'perpetrator',
      minWidth: 200,
      maxWidth: 200
    },
    {
      key: 'created_date',
      name: 'Datum kreiranja',
      fieldName: 'created_date',
      minWidth: 150,
      maxWidth: 150,
      onColumnClick: () => onSort('created_date'),
      isSortedDescending: sortedAsc,
      isSorted: sortedBy === 'created_date',
      styles: {
        root: {
          cursor: 'pointer'
        }
      }
    },
    {
      key: 'type',
      name: 'Tip pomoći',
      fieldName: 'type',
      minWidth: 200,
      maxWidth: 200
    },
    {
      key: 'moreButton',
      name: '',
      fieldName: 'moreButton',
      minWidth: 150,
      maxWidth: 150
    }
  ],

  PerpetratorColumns: [
    {
      key: 'first_name',
      name: 'Ime',
      fieldName: 'first_name',
      minWidth: 120,
      maxWidth: 120
    },
    {
      key: 'last_name',
      name: 'Prezime',
      fieldName: 'last_name',
      minWidth: 120,
      maxWidth: 120,
      onColumnClick: () => onSort('last_name'),
      isSortedDescending: sortedAsc,
      isSorted: sortedBy === 'last_name',
      styles: {
        root: {
          cursor: 'pointer'
        }
      }
    },
    {
      key: 'created_date',
      name: 'Kreirano',
      fieldName: 'created_date',
      minWidth: 120,
      maxWidth: 120,
      onColumnClick: () => onSort('created_date'),
      isSortedDescending: sortedAsc,
      isSorted: sortedBy === 'created_date',
      styles: {
        root: {
          cursor: 'pointer'
        }
      }
    },
    {
      key: 'birth_date',
      name: 'Datum rođenja',
      fieldName: 'birth_date',
      minWidth: 100,
      maxWidth: 100
    },
    {
      key: 'birth_place',
      name: 'Mjesto rođenja',
      fieldName: 'birth_place',
      minWidth: 100,
      maxWidth: 100
    },
    {
      key: 'address',
      name: 'Adresa',
      fieldName: 'address',
      minWidth: 150,
      maxWidth: 150
    },
    {
      key: 'moreButton',
      name: '',
      fieldName: 'moreButton',
      minWidth: 150,
      maxWidth: 150
    }
  ],

  SafehouseColumns: [
    {
      key: 'victim',
      name: 'Žrtva nasilja',
      fieldName: 'victim',
      minWidth: 200,
      maxWidth: 200,
      onColumnClick: () => onSort('victim_last_name'),
      isSortedDescending: sortedAsc,
      isSorted: sortedBy === 'victim_last_name',
      styles: {
        root: {
          cursor: 'pointer'
        }
      }
    },
    {
      key: 'perpetrator',
      name: 'Počinilac nasilja',
      fieldName: 'perpetrator',
      minWidth: 200,
      maxWidth: 200
    },
    {
      key: 'arrival_of_victim',
      name: 'Dolazak žrtve',
      fieldName: 'arrival_of_victim',
      minWidth: 100,
      maxWidth: 100
    },
    {
      key: 'created_date',
      name: 'Kreirano',
      fieldName: 'created_date',
      minWidth: 250,
      maxWidth: 250,
      onColumnClick: () => onSort('created_date'),
      isSortedDescending: sortedAsc,
      isSorted: sortedBy === 'created_date',
      styles: {
        root: {
          cursor: 'pointer'
        }
      }
    },
    {
      key: 'moreButton',
      name: '',
      fieldName: 'moreButton',
      minWidth: 150,
      maxWidth: 150
    }
  ],

  SosCallColumns: [
    {
      key: 'victim',
      name: 'Žrtva nasilja',
      fieldName: 'victim',
      minWidth: 200,
      maxWidth: 200,
      onColumnClick: () => onSort('victim_last_name'),
      isSortedDescending: sortedAsc,
      isSorted: sortedBy === 'victim_last_name',
      styles: {
        root: {
          cursor: 'pointer'
        }
      }
    },
    {
      key: 'perpetrator',
      name: 'Počinilac nasilja',
      fieldName: 'perpetrator',
      minWidth: 200,
      maxWidth: 200
    },
    {
      key: 'date',
      name: 'Datum poziva',
      fieldName: 'date',
      minWidth: 100,
      maxWidth: 100
    },
    {
      key: 'created_date',
      name: 'Datum kreiranja',
      fieldName: 'created_date',
      minWidth: 250,
      maxWidth: 250,
      onColumnClick: () => onSort('created_date'),
      isSortedDescending: sortedAsc,
      isSorted: sortedBy === 'created_date',
      styles: {
        root: {
          cursor: 'pointer'
        }
      }
    },
    {
      key: 'moreButton',
      name: '',
      fieldName: 'moreButton',
      minWidth: 150,
      maxWidth: 150
    }
  ],

  TrustPersonColumns: [
    {
      key: 'name',
      name: 'Ime osobe od povjerenja',
      fieldName: 'name',
      minWidth: 200,
      maxWidth: 200
    },
    {
      key: 'victim',
      name: 'Žrtva nasilja',
      fieldName: 'victim',
      minWidth: 200,
      maxWidth: 200,
      onColumnClick: () => onSort('victim_last_name'),
      isSortedDescending: sortedAsc,
      isSorted: sortedBy === 'victim_last_name',
      styles: {
        root: {
          cursor: 'pointer'
        }
      }
    },
    {
      key: 'institution',
      name: 'Institucija u kojoj je pružena pomoć',
      fieldName: 'institution',
      minWidth: 200,
      maxWidth: 200
    },
    {
      key: 'created_date',
      name: 'Datum kreiranja',
      fieldName: 'created_date',
      minWidth: 150,
      maxWidth: 150,
      onColumnClick: () => onSort('created_date'),
      isSortedDescending: sortedAsc,
      isSorted: sortedBy === 'created_date',
      styles: {
        root: {
          cursor: 'pointer'
        }
      }
    },
    {
      key: 'moreButton',
      name: '',
      fieldName: 'moreButton',
      minWidth: 150,
      maxWidth: 150
    }
  ],

  VictimColumns: [
    {
      key: 'first_name',
      name: 'Ime',
      fieldName: 'first_name',
      minWidth: 120,
      maxWidth: 120
    },
    {
      key: 'last_name',
      name: 'Prezime',
      fieldName: 'last_name',
      minWidth: 120,
      maxWidth: 120,
      onColumnClick: () => onSort('last_name'),
      isSortedDescending: sortedAsc,
      isSorted: sortedBy === 'last_name',
      styles: {
        root: {
          cursor: 'pointer'
        }
      }
    },
    {
      key: 'created_date',
      name: 'Datum kreiranja',
      fieldName: 'created_date',
      minWidth: 120,
      maxWidth: 120,
      onColumnClick: () => onSort('created_date'),
      isSortedDescending: sortedAsc,
      isSorted: sortedBy === 'created_date',
      styles: {
        root: {
          cursor: 'pointer'
        }
      }
    },
    {
      key: 'birth_date',
      name: 'Datum rođenja',
      fieldName: 'birth_date',
      minWidth: 100,
      maxWidth: 100
    },
    {
      key: 'birth_place',
      name: 'Mjesto rođenja',
      fieldName: 'birth_place',
      minWidth: 100,
      maxWidth: 100
    },
    {
      key: 'address',
      name: 'Adresa',
      fieldName: 'address',
      minWidth: 150,
      maxWidth: 150
    },
    {
      key: 'moreButton',
      name: '',
      fieldName: 'moreButton',
      minWidth: 150,
      maxWidth: 150
    }
  ],

  UserColumns: [
    {
      key: 'first_name',
      name: 'Ime',
      fieldName: 'first_name',
      minWidth: 200,
      maxWidth: 200
    },
    {
      key: 'last_name',
      name: 'Prezime',
      fieldName: 'last_name',
      minWidth: 200,
      maxWidth: 200
    },
    {
      key: 'username',
      name: 'Korisničko ime',
      fieldName: 'username',
      minWidth: 200,
      maxWidth: 200
    },
    {
      key: 'date_created',
      name: 'Nalog kreiran',
      fieldName: 'date_created',
      minWidth: 150,
      maxWidth: 150
    },
    {
      key: 'role',
      name: 'Uloga',
      fieldName: 'role',
      minWidth: 150,
      maxWidth: 150
    }
  ],

  HelpColumns: [
    {
      key: 'value',
      name: 'Tip',
      fieldName: 'value',
      minWidth: 250,
      maxWidth: 250
    },
    {
      key: 'created_date',
      name: 'Datum',
      fieldName: 'created_date',
      minWidth: 150,
      maxWidth: 150
    },
    {
      key: 'notes',
      name: 'Napomene',
      fieldName: 'notes',
      minWidth: 350,
      maxWidth: 350
    },
    {
      key: 'delete',
      name: '',
      fieldName: 'delete',
      minWidth: 100,
      maxWidth: 100
    }
  ],

  ChildrenColumns: [
    {
      key: 'name',
      name: 'Ime',
      fieldName: 'name',
      minWidth: 310,
      maxWidth: 310
    },
    {
      key: 'delete',
      name: '',
      fieldName: 'delete',
      minWidth: 100,
      maxWidth: 100
    },
    {
      key: 'moreButton',
      name: '',
      fieldName: 'moreButton',
      minWidth: 150,
      maxWidth: 150
    }
  ],

  FileColumns: [
    {
      key: 'filename',
      name: 'Naziv',
      fieldName: 'filename',
      minWidth: 600,
      maxWidth: 600
    },
    {
      key: 'created_date',
      name: 'Datum kreiranja',
      fieldName: 'created_date',
      minWidth: 190,
      maxWidth: 190
    },
    {
      key: 'download',
      name: '',
      fieldName: 'download',
      minWidth: 150,
      maxWidth: 150
    }
  ]
}
)
