import { COLORS } from '../../assets/theme/colors'

export const ServiceCodesStyle = () => ({
  container: {
    width: '600px'
  },
  row: {
    width: '100%'
  },
  menuStyle: {
    container: {
      overflowY: 'hidden',
      overflowX: 'hidden',
      width: '80px',
      minWidth: '80px',
      '.ms-ContextualMenu-itemText': {
        fontSize: '12px'
      }
    }
  },
  iconStyle: {
    root: {
      color: COLORS.primary
    }
  }
})
