/* eslint-disable brace-style */
import { Stack, DetailsList, SelectionMode, Spinner } from '@fluentui/react'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { getAllTrustPersonEntries } from '../../assets/axios/services'
import { Columns } from '../../assets/consts/Columns'
import PageNavigation from '../styledComponents/PageNavigation'
import StyledButton from '../styledComponents/StyledButton'
import { TrustPersonStyle } from './TrustPerson.style'

export default function TrustPersonList () {
  const style = TrustPersonStyle()
  const navigate = useNavigate()
  const [pagination, setPagination] = useState({})
  const [actionInProgress, setActionInProgress] = useState(false)

  const [data, setData] = useState([])
  const [filter, setFilter] = useState({
    page: 1,
    searchBy: null,
    sortBy: 'created_date',
    sortAsc: false
  })

  const onPageChange = (page: any) => {
    setFilter({ ...filter, page })
  }
  useEffect(() => {
    setActionInProgress(true)
    getAllTrustPersonEntries(filter).then((res) => {
      setData(res.data.items)
      setPagination({
        total: res.data.total,
        page: res.data.page,
        size: res.data.size
      })
      setActionInProgress(false)
    }
    )
  }, [filter])

  let searchTimeout: any = null

  const onSearch = (searchBy: any) => {
    if (searchBy.length > 2) {
      if (searchTimeout) clearTimeout(searchTimeout)
      searchTimeout = setTimeout(() => {
        if (filter.searchBy !== searchBy) {
          setFilter({ ...filter, searchBy })
        }
      }, 1000)
    } else {
      setFilter({ ...filter, searchBy: null })
    }
  }

  const onRender = (item: any, index: any, column: any) => {
    if (column != null) {
      switch (column?.key) {
        case 'moreButton':
          return (
            <Stack style={ style.row } horizontalAlign='end'>
              <StyledButton
          height='20px'
          onClick={ () => {
            navigate(`/services/trustperson/${item.id}`)
          } }
          text='Pregledaj'
          />
            </Stack>
          )

        case 'victim':
          return (<>{ `${item.victim_first_name} ${item.victim_last_name}` }</>)

        case 'perpetrator':
          return (<>{ `${item.perpetrator_first_name ?? ''} ${item.perpetrator_last_name ?? ''}` }</>)

        case 'created_date':
          return (<>{ moment(item[column?.key]).format('DD.MM.YYYY') }</>)

        default:
          return (<>{ item[column?.key] }</>)
      }
    }
  }

  return (
    <Stack style={{ maxWidth: '1000px' }}>
      <Stack horizontal style={ style.topRow } horizontalAlign='space-between' verticalAlign='center'>
        <PageNavigation pagination={ pagination } onPageChange={ onPageChange } onSearch={ onSearch }/>
        <StyledButton
        text='Dodaj'
        onClick={ () => { navigate('/services/trustperson/new') } }
        />
      </Stack>
      { actionInProgress
        ? <Spinner style={{ paddingTop: '50px' }}/>
        : <> <DetailsList
      onRenderItemColumn={ onRender }
      items={ data }
      columns={ Columns((sortBy: any) => setFilter({ ...filter, sortBy, sortAsc: !(filter.sortAsc) }),
        filter.sortAsc, filter.sortBy).TrustPersonColumns }
      selectionMode={ SelectionMode.none }
      />
          { data.length === 0 &&
            <Stack horizontal horizontalAlign='center' style={{ userSelect: 'none' }}>
              nema podataka
            </Stack> }
        </>
      }
    </Stack>
  )
}
