import React from 'react'
import { Stack } from '@fluentui/react'
import SosCall from './images/soscall.jpg'
import SafeHouse from './images/safeHouse.jpg'
import TrustPerson from './images/trustworthyperson.jpg'
import AdvisoryHelp from './images/advisoryservices.jpg'
import NavigationCard from './NavigationCard'
import { ServiceTypeStyles } from './ServiceTypes.style'

export default function ServiceTypes () {
  const style = ServiceTypeStyles()

  return (
    <Stack style={ style.container } wrap tokens={{ childrenGap: 30 }} horizontal horizontalAlign='space-between'>

      <NavigationCard
        imageSrc={ SosCall }
        label='SOS telefon'
        link='/services/soscall'
        />
      <NavigationCard
        imageSrc={ SafeHouse }
        label='Sigurna kuća'
        link='/services/safehouse'
        />

      <NavigationCard
        imageSrc={ AdvisoryHelp }
        label='Savjetodavne usluge'
        link='/services/advisoryhelp'
        />
      <NavigationCard
        imageSrc={ TrustPerson }
        label='Lice od povjerenja'
        link='/services/trustperson'
        />

    </Stack>

  )
}
