import { TextField } from '@fluentui/react'
import React from 'react'
import { StyledComponentsStyle } from './StyledComponents.style'

export default function StyledTextField ({ ...rest }) {
  const style = StyledComponentsStyle()
  return <TextField
  placeholder={ rest.placeholder ?? rest.label }
  styles={ style.textField }
    { ...rest }
  />
}
