import React from 'react'
import MainContentContainer from '../../components/containers/MainContentContainer'
import VictimList from '../../components/victim/VictimList'

export default function VictimListView () {
  return (
    <MainContentContainer
        title={ 'Lista žrtvi nasilja' }
        content={ <VictimList/> }
        />

  )
}
