/* eslint-disable brace-style */
import React, { useEffect, useState } from 'react'
import { Checkbox, Label, MessageBar, MessageBarType, Spinner, Stack } from '@fluentui/react'
import SearchableDropdown from '../styledComponents/SearchableDropdown'
import StyledButton from '../styledComponents/StyledButton'
import { useNavigate, useParams } from 'react-router-dom'
import { createSoscallEntry, deleteSoscallEntry, editSoscallEntry, getSoscallEntry } from '../../assets/axios/services'
import { SosCallStyle } from './SosCall.style'
import moment from 'moment'
import StyledDatePicker from '../styledComponents/StyledDatePicker'
import StyledCommentField from '../styledComponents/StyledCommentField'

export default function SosCallForm () {
  const [formData, setFormData] = useState<any>({ anonymous: false })
  const [message, setMessage] = useState({ type: MessageBarType.success, text: '' })
  const [actionInProgress, setActionInProgress] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [isEditable, setIsEditable] = useState(true)

  const style = SosCallStyle()
  const navigate = useNavigate()

  const { id } = useParams()

  useEffect(() => {
    if (id !== undefined) {
      setIsEdit(true)
      setIsEditable(false)
      getSoscallEntry(id).then((res) => {
        setFormData(res.data)
      })
    }
  }, [])

  const onInput = (fieldName: string, value: string | number | boolean | undefined) => {
    setFormData({ ...formData, [fieldName]: value })
  }

  const onError = (err: any) => {
    setActionInProgress(false)
    setMessage({ type: MessageBarType.error, text: err.message })
  }

  const onSubmit = () => {
    setActionInProgress(true)
    if (isEdit) {
      editSoscallEntry(id, formData).then(() => {
        setMessage({ type: MessageBarType.success, text: 'Unos uspiješno izmijenjen.' })
        setTimeout(() => {
          setIsEditable(false)
          setActionInProgress(false)
          setTimeout(() => {
            setMessage({ type: MessageBarType.success, text: '' })
          }, 1500)
        }, 1500)
      }).catch((err) => {
        onError(err)
      })
    } else {
      createSoscallEntry(formData).then(() => {
        setMessage({ type: MessageBarType.success, text: 'Unos uspiješno kreiran.' })
        setTimeout(() => {
          navigate('/services/soscall')
        }, 1500)
      }).catch((err) => {
        onError(err)
      })
    }
  }

  return (
    <Stack tokens={{ childrenGap: 50 }}>
      <Stack tokens={{ childrenGap: 15 }}>
        { message.text.length > 0
          ? <MessageBar
            onDismiss={ () => { setMessage({ ...message, text: '' }) } }
            messageBarType={ message.type }
            styles={ style.messageBar }>
            { message.text }
          </MessageBar>
          : <div style={{ height: '32px' }} />
        }
        <Stack horizontal style={{ maxWidth: '1000px' }} tokens={{ childrenGap: 20 }} wrap enableScopedSelectors>
          { isEdit
            ? <Stack style={{ width: '300px' }}>
              <Label style={ style.label }>Žrtva nasilja</Label>
              <span style={ style.labeledText }>
                { formData?.anonymous
                  ? 'Anonimni poziv'
                  : `${formData?.victim_first_name} ${formData?.victim_last_name}` }
              </span>
            </Stack>
            : <Stack tokens={{ childrenGap: 15 }}>
              { formData?.anonymous === false

                ? <SearchableDropdown
                  disabled={ formData?.anonymous === true }
                  required
                  label='Žrtva nasilja'
                  searchBy='victim'
                  onChange={ (e: any, value: any) => {
                    onInput('victim_id', value.key)
                  } }
                />
                : <Stack style={{ width: '300px' }}>
                  <Label style={ style.label }>Žrtva nasilja</Label>
                  <span style={ style.labeledText }>
                    Anonimni poziv
                  </span>
                </Stack>

              }
              <Stack horizontal style={{ width: '300px' }}
                verticalAlign='center' horizontalAlign='start' tokens={{ childrenGap: 15 }}>
                <Label style={ style.label }>Anonimna prijava:</Label>
                <Checkbox
                  checked={ formData?.anonymous }
                  onChange={ (e, checked) => {
                    if (checked) {
                      delete formData?.victim_id
                    }
                    onInput('anonymous', checked)
                  } } />
              </Stack>
            </Stack>
          }

          { isEdit
            ? <Stack style={{ width: '320px' }}>
              <Label style={ style.label }>Počinilac nasilja</Label>
              <span style={ style.labeledText }>
                { `${formData?.perpetrator_first_name ?? ''} ${formData?.perpetrator_last_name ?? ''}` }
              </span>
            </Stack>
            : <SearchableDropdown
              label='Počinilac nasilja'
              searchBy='perpetrator'
              onChange={ (e: any, value: any) => { onInput('perpetrator_id', value.key) } }
            /> }
          <StyledDatePicker
                disabled={ !isEditable }
                value={ formData?.date !== undefined && formData.date !== null
                  ? new Date(moment(formData.date, 'DD.MM.YYYY').format())
                  : undefined }
                label='Datum poziva'
                onSelectDate={ (date: any) => { onInput('date', moment(date).format('DD.MM.YYYY')) } }
              />
          <StyledCommentField
            value={ formData?.notes }
            disabled={ !isEditable }
            required
            label='Opis pružene pomoći'
            name='notes'
            multiline rows={ 4 }
            onChange={ (e: any, value: any) => { onInput(e.target.name, value) } }
          />

        </Stack>
      </Stack>
      <Stack horizontal horizontalAlign='start' tokens={{ childrenGap: 30 }} >
        <StyledButton
          disabled={ formData?.notes === undefined ||
            formData?.notes.length === 0 ||
            (formData?.victim_id === undefined && formData?.anonymous === false) ||
            actionInProgress }
          onClick={ () => {
            !isEditable
              ? setIsEditable(true)
              : onSubmit()
          } }>
          { actionInProgress ? <Spinner /> : (isEditable ? 'Sačuvaj' : 'Izmjeni') }
        </StyledButton>
        <StyledButton
          red
          disabled={ (!isEditable || actionInProgress) || id === undefined }
          onClick={ () => {
            setActionInProgress(true)
            deleteSoscallEntry(id).then(() => {
              setMessage({ type: MessageBarType.success, text: 'Unos obrisan.' })
              setTimeout(() => {
                navigate('/services/soscall')
              }, 1500)
            }).catch(() => {
              setActionInProgress(false)
              setMessage({ type: MessageBarType.error, text: 'Greška. Pokušajte ponovo.' })
            })
          } }>
          { actionInProgress ? <Spinner /> : 'Obriši' }
        </StyledButton>
      </Stack>
    </Stack>

  )
}
