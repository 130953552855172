/* eslint-disable comma-dangle */
/* eslint-disable no-trailing-spaces */
/* eslint-disable brace-style */
import React, { useEffect, useState } from 'react'
import {
  DetailsList, DetailsRow, DropdownMenuItemType,
  IconButton,
  Label, MessageBar, MessageBarType, Pivot, PivotItem, SelectionMode, Spinner, Stack
} from '@fluentui/react'
import StyledButton from '../styledComponents/StyledButton'
import SearchableDropdown from '../styledComponents/SearchableDropdown'
import StyledDatePicker from '../styledComponents/StyledDatePicker'
import moment from 'moment'
import {
  createSafehouseEntry, createSafehouseEntryHelpEntry, editSafehouseEntry,
  getSafehouseDataTypes, searchSafehouseEntry, getSafehouseEntryHelpEntries, deleteSafehouseHelpEntry, deleteSafehouseEntry
} from '../../assets/axios/services'
import { useNavigate, useParams } from 'react-router-dom'
import { SafeHouseStyle } from './SafeHouse.style'
import StyledTextField from '../styledComponents/StyledTextField'
import StyledDropdown from '../styledComponents/StyledDropdown'
import { Columns } from '../../assets/consts/Columns'
import StyledCommentField from '../styledComponents/StyledCommentField'

export default function SafeHouseForm () {
  const [formData, setFormData] = useState<any>(null)
  const [message, setMessage] = useState({ type: MessageBarType.success, text: '' })
  const [actionInProgress, setActionInProgress] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [isEditable, setIsEditable] = useState(true)
  const [dropdownData, setDropdownData] = useState<any>([])
  const [selectedHelpTypes, setSelectedHelpTypes] = useState<any>([])
  const [psychologicalHelp, setPsychologicalHelp] = useState([])
  const [socialHelp, setSocialHelp] = useState([])
  const [legalHelp, setLegalHelp] = useState([])
  const [healthHelp, setHealthHelp] = useState([])
  const [reintegrationPlan, setReintegrationPlan] = useState([])
  const [helpDate, setHelpDate] = useState(moment().format('DD.MM.YYYY'))
  const [helpNote, setHelpNote] = useState('')
  const [forDeletion, setForDeletion] = useState<any>([])

  const navigate = useNavigate()

  const onInput = (fieldName: string, value: string | number) => {
    setFormData({ ...formData, [fieldName]: value })
  }

  const { id } = useParams()

  const filterByType = (data: any, type: string) => {
    return data.filter((item: any) => item.type === type)
  }

  const resolvePreviousHelpData = (data: any) => {
    setPsychologicalHelp(filterByType(data, 'Psihološka pomoć'))
    setSocialHelp(filterByType(data, 'Socijalna pomoć'))
    setLegalHelp(filterByType(data, 'Pravna pomoć'))
    setHealthHelp(filterByType(data, 'Zdravstvena pomoć'))
    setReintegrationPlan(filterByType(data, 'Plan reintegracije'))
  }
  const generateOptions = (data: any) => {
    return [
      { key: 'psychologicalHelp', text: 'Psihološka pomoć', itemType: DropdownMenuItemType.Header },
      ...filterByType(data, 'Psihološka pomoć').map((item: any) => ({
        text: item.under_type,
        type: item.type,
        key: `${item.type}-${item.under_type}`
      })),
      { key: 'socialHelp', text: 'Socijalna pomoć', itemType: DropdownMenuItemType.Header },
      ...filterByType(data, 'Socijalna pomoć').map((item: any) => ({
        text: item.under_type,
        type: item.type,
        key: `${item.type}-${item.under_type}`
      })),
      { key: 'legalHelp', text: 'Pravna pomoć', itemType: DropdownMenuItemType.Header },
      ...filterByType(data, 'Pravna pomoć').map((item: any) => ({
        text: item.under_type,
        type: item.type,
        key: `${item.type}-${item.under_type}`
      })),
      { key: 'healthHelp', text: 'Zdravstvena pomoć', itemType: DropdownMenuItemType.Header },
      ...filterByType(data, 'Zdravstvena pomoć').map((item: any) => ({
        text: item.under_type,
        type: item.type,
        key: `${item.type}-${item.under_type}`
      })),
      { key: 'reintegrationPlan', text: 'Plan reintegracije', itemType: DropdownMenuItemType.Header },
      ...filterByType(data, 'Plan reintegracije').map((item: any) => ({
        text: item.under_type,
        type: item.type,
        key: `${item.type}-${item.under_type}`
      }))
    ]
  }

  const handleMultiSelect = (value: any) => {
    if (value.selected) {
      setSelectedHelpTypes([...selectedHelpTypes, value])
    } else {
      setSelectedHelpTypes(selectedHelpTypes.filter((item: any) => item.key !== value.key))
    }
  }

  const getCurrentSelection = (data: any) => {
    return data.map((item: any) => (item.key))
  }
  const onRender = (item: any, index: any, column: any) => {
    if (column != null) {
      switch (column?.key) {
        case 'delete':
          return (<Stack style={{ height: '100%', width: '100%' }}
            verticalAlign='center' horizontalAlign='center'>
            <IconButton iconProps={{ iconName: 'delete' }} onClick={ () => {
              setForDeletion([...forDeletion, item.id])
            } } 
            disabled={ forDeletion.includes(item.id) }
            />
          </Stack>)

        default:
          return (<Stack style={{ height: '100%' }}
          verticalAlign='center'>{ item[column?.key] }</Stack>)
      }
    }
  }

  useEffect(() => {
    if (id !== undefined) {
      setIsEdit(true)
      setIsEditable(false)
      searchSafehouseEntry(id).then((res) => {
        setFormData(res.data)
      })
      getSafehouseEntryHelpEntries(id).then((res) => {
        resolvePreviousHelpData(res.data)
      })
    }
    getSafehouseDataTypes().then((res) => {
      setDropdownData(generateOptions(res.data.items))
    })
  }, [])

  const onSubmit = () => {
    setActionInProgress(true)
    if (isEdit) {
      editSafehouseEntry(id, formData).then(() => {
        selectedHelpTypes.forEach((item: any) => {
          createSafehouseEntryHelpEntry({
            safehouse_id: id,
            value: item.text,
            type: item.type,
            created_date: helpDate,
            notes: helpNote
          })
        })
        forDeletion.forEach((item: any) => {
          deleteSafehouseHelpEntry(item)
        })
        setMessage({ type: MessageBarType.success, text: 'Unos uspiješno izmijenjen.' })
        setTimeout(() => {
          setIsEditable(false)
          setTimeout(() => {
            window.location.reload()
          }, 1500)
        }, 1500)
      }).catch((err) => {
        setActionInProgress(false)
        setMessage({ type: MessageBarType.error, text: err.message })
      })
    } else {
      createSafehouseEntry(formData).then((res) => {
        selectedHelpTypes.forEach((item: any) => {
          createSafehouseEntryHelpEntry({
            safehouse_id: res.data.id,
            value: item.text,
            type: item.type,
            created_date: helpDate,
            notes: helpNote
          })
        })
        setMessage({ type: MessageBarType.success, text: 'Unos uspiješno kreiran.' })
        setTimeout(() => {
          navigate('/services/safehouse')
        }, 1500)
      }).catch((err) => {
        setActionInProgress(false)
        setMessage({ type: MessageBarType.error, text: err.message })
      })
    }
  }

  const style = SafeHouseStyle()

  return (
    <Stack tokens={{ childrenGap: 50 }}>
      <Stack tokens={{ childrenGap: 15 }}>
        { message.text.length > 0
          ? <MessageBar
            onDismiss={ () => { setMessage({ ...message, text: '' }) } }
            messageBarType={ message.type }
            styles={ style.messageBar }>
            { message.text }
          </MessageBar>
          : <div style={{ height: '32px' }} />
        }
        <Stack horizontal style={{ maxWidth: '1000px' }} tokens={{ childrenGap: 20 }} wrap enableScopedSelectors>
          { isEdit
            ? <Stack style={{ width: '300px' }}>
              <Label style={ style.label }>Žrtva nasilja</Label>
              <span style={ style.labeledText }>
                { `${formData?.victim_first_name} ${formData?.victim_last_name}` }
              </span>
            </Stack>
            : <SearchableDropdown
              required
              label='Žrtva nasilja'
              searchBy='victim'
              onChange={ (e: any, value: any) => { onInput('victim_id', value.key) } }
            /> }
          { isEdit
            ? <Stack style={{ width: '300px' }}>
              <Label style={ style.label }>Počinilac nasilja</Label>
              <span style={ style.labeledText }>
                { `${formData?.perpetrator_first_name ?? ''} ${formData?.perpetrator_last_name ?? ''}` }
              </span>
            </Stack>
            : <SearchableDropdown

              label='Počinilac nasilja'
              searchBy='perpetrator'
              onChange={ (e: any, value: any) => { onInput('perpetrator_id', value.key) } }
            /> }
          <StyledDropdown
            disabled={ !isEditable }
            label='Tip pružene pomoći'
            options={ dropdownData }
            multiSelect
            selectedKeys={ getCurrentSelection(selectedHelpTypes) }
            onChange={ (e: any, value: any) => { handleMultiSelect(value) } }
          />

          <StyledDatePicker
            disabled={ !isEditable }
            label='Dolazak žrtve'
            value={ formData?.arrival_of_victim !== undefined && formData?.arrival_of_victim !== null
              ? new Date(moment(formData.arrival_of_victim, 'DD.MM.YYYY').format())
              : undefined }
            onSelectDate={ (date: any) => { onInput('arrival_of_victim', moment(date).format('DD.MM.YYYY')) } }
          />
          <StyledDatePicker
            disabled={ formData?.arrival_of_victim === undefined || !isEditable }
            label='Odlazak žrtve'
            value={ formData?.leaving_of_victim !== undefined && formData?.leaving_of_victim !== null
              ? new Date(moment(formData.leaving_of_victim, 'DD.MM.YYYY').format())
              : undefined }
            onSelectDate={ (date: any) => { onInput('leaving_of_victim', moment(date).format('DD.MM.YYYY')) } }
          />
          <StyledDatePicker
            disabled={ !isEditable }
            label='Datum pružanja pomoći'
            value={ new Date(moment(helpDate, 'DD.MM.YYYY').format()) }
            onSelectDate={ (date: any) => { setHelpDate(moment(date).format('DD.MM.YYYY')) } }
          />
          <StyledCommentField
            value={ formData?.notes }
            disabled={ !isEditable }
            label='Komentar'
            name='notes'
            multiline rows={ 3 }
            onChange={ (e: any, value: any) => { onInput(e.target.name, value) } }
          />
          <StyledTextField
            value={ helpNote }
            disabled={ !isEditable || selectedHelpTypes.length === 0 }
            label='Informacije o pruženoj pomoći'
            name='helpNote'
            onChange={ (e: any, value: any) => { setHelpNote(value) } }
          />
          { isEdit && <Stack style={{ textAlign: 'left', maxWidth: '1000px' }}>
            <Label style={ style.label }>Ranije pružene pomoći</Label>
            <Pivot styles={{
              itemContainer: {
                marginTop: '-15px'
              }
            }}>
              <PivotItem headerText='Psihološka pomoć'>
                <DetailsList
                  selectionMode={ SelectionMode.none }
                  columns={ Columns().HelpColumns }
                  items={ psychologicalHelp }
                  onRenderItemColumn={ (item, index, column) => onRender(item, index, column) }
                  onRenderRow={ (props: any) => (
                    <DetailsRow { ...props } 
                    styles={{
                      root: {
                        backgroundColor: forDeletion.includes(props.item.id) ? '#cf4444' : 'white',
                        color: forDeletion.includes(props.item.id) ? 'white' : 'black',
                        selectors: {
                          ':hover': {
                            backgroundColor: forDeletion.includes(props.item.id) ? '#cf4444' : 'white',
                            color: forDeletion.includes(props.item.id) ? 'white' : 'black'
                          } 
                        }
                      } 
                    }}/>
                  ) 
                  }
                />
              </PivotItem>
              <PivotItem headerText='Socijalna pomoć'>
                <DetailsList
                  selectionMode={ SelectionMode.none }
                  columns={ Columns().HelpColumns }
                  items={ socialHelp }
                  onRenderItemColumn={ (item, index, column) => onRender(item, index, column) }
                 
                />
              </PivotItem>
              <PivotItem headerText='Pravna pomoć'>
                <DetailsList
                  selectionMode={ SelectionMode.none }
                  columns={ Columns().HelpColumns }
                  items={ legalHelp }
                  onRenderItemColumn={ (item, index, column) => onRender(item, index, column) }
                />
              </PivotItem>
              <PivotItem headerText='Zdravstvena pomoć'>
                <DetailsList
                  selectionMode={ SelectionMode.none }
                  columns={ Columns().HelpColumns }
                  items={ healthHelp }
                  onRenderItemColumn={ (item, index, column) => onRender(item, index, column) }
                />
              </PivotItem>
              <PivotItem headerText='Plan reintegracije'>
                <DetailsList
                  selectionMode={ SelectionMode.none }
                  columns={ Columns().HelpColumns }
                  items={ reintegrationPlan }
                  onRenderItemColumn={ (item, index, column) => onRender(item, index, column) }
                />
              </PivotItem>
            </Pivot>
          </Stack>
          }

        </Stack>
      </Stack>
      <Stack horizontal style={ style.row } tokens={{ childrenGap: 30 }} horizontalAlign='start'>
        <StyledButton
          disabled={ formData?.victim_id === undefined || actionInProgress }
          onClick={ () => {
            !isEditable
              ? setIsEditable(true)
              : onSubmit()
          } }>
          { actionInProgress ? <Spinner /> : (isEditable ? 'Sačuvaj' : 'Izmjeni') }
        </StyledButton>
        <StyledButton
          red
          disabled={ (!isEditable || actionInProgress) || id === undefined }
          onClick={ () => {
            setActionInProgress(true)
            deleteSafehouseEntry(id).then(() => {
              setMessage({ type: MessageBarType.success, text: 'Unos obrisan.' })
              setTimeout(() => {
                navigate('/services/safehouse')
              }, 1500)
            }).catch(() => {
              setActionInProgress(false)
              setMessage({ type: MessageBarType.error, text: 'Greška. Pokušajte ponovo.' })
            })
          } }>
          { actionInProgress ? <Spinner /> : 'Obriši' }
        </StyledButton>
      </Stack>
    </Stack>

  )
}
