/* eslint-disable no-trailing-spaces */
/* eslint-disable comma-dangle */
/* eslint-disable brace-style */
import React, { useEffect, useState } from 'react'
import { Label, MessageBar, MessageBarType, Spinner, Stack } from '@fluentui/react'
import SearchableDropdown from '../styledComponents/SearchableDropdown'
import StyledButton from '../styledComponents/StyledButton'
import { useNavigate, useParams } from 'react-router-dom'
import {
  createTrustPersonEntry,
  deleteTrustedPersonEntry,
  editTrustPeronsEntry,
  getTrustedPersonEntry 
} from '../../assets/axios/services'
import StyledTextField from '../styledComponents/StyledTextField'
import { TrustPersonStyle } from './TrustPerson.style'
import moment from 'moment'
import StyledDatePicker from '../styledComponents/StyledDatePicker'
import { DropdownOptions } from '../../assets/consts/DropdownOptions'
import DropdownWithOptionalField from '../styledComponents/DropdownWithOptionalField'

export default function TrustPersonForm () {
  const [formData, setFormData] = useState<any>(null)
  const [message, setMessage] = useState({ type: MessageBarType.success, text: '' })
  const [actionInProgress, setActionInProgress] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [isEditable, setIsEditable] = useState(true)

  const style = TrustPersonStyle()
  const navigate = useNavigate()

  const { id } = useParams()

  useEffect(() => {
    if (id !== undefined) {
      setIsEdit(true)
      setIsEditable(false)
      getTrustedPersonEntry(id).then((res) => {
        setFormData(res.data)
      })
    }
  }, [])

  const onInput = (fieldName: string, value: string | number | undefined) => {
    setFormData({ ...formData, [fieldName]: value })
  }

  const onError = (err: any) => {
    setActionInProgress(false)
    setMessage({ type: MessageBarType.error, text: err.message })
  }

  const onSubmit = () => {
    setActionInProgress(true)
    if (isEdit) {
      editTrustPeronsEntry(id, formData).then(() => {
        setMessage({ type: MessageBarType.success, text: 'Unos uspiješno izmijenjen.' })
        setTimeout(() => {
          setIsEditable(false)
          setActionInProgress(false)
          setTimeout(() => {
            setMessage({ type: MessageBarType.success, text: '' })
          }, 1500)
        }, 1500)
      }).catch((err) => {
        onError(err)
      })
    } else {
      createTrustPersonEntry(formData).then(() => {
        setMessage({ type: MessageBarType.success, text: 'Unos uspiješno kreiran.' })
        setTimeout(() => {
          navigate('/services/trustperson')
        }, 1500)
      }).catch((err) => {
        onError(err)
      })
    }
  }

  return (
    <Stack tokens={{ childrenGap: 50 }}>
      <Stack tokens={{ childrenGap: 15 }}>
        { message.text.length > 0
          ? <MessageBar
        onDismiss={ () => { setMessage({ ...message, text: '' }) } }
        messageBarType={ message.type }
        styles={ style.messageBar }>
            { message.text }
          </MessageBar>
          : <div style={{ height: '32px' }}/>
      }
        <Stack horizontal style={{ maxWidth: '1000px' }} tokens={{ childrenGap: 20 }} wrap enableScopedSelectors>
          <StyledTextField
          disabled={ !isEditable }
          value={ formData?.name }
          label='Ime i prezime osobe od povjerenja'
          required
          name='name'
          onChange={ (e: any, value: any) => { onInput(e.target.name, value) } }
          />
          { isEdit
            ? <Stack style={{ width: '300px' }}>
              <Label style={ style.label }>Žrtva nasilja</Label>
              <span style={ style.labeledText }>
                { `${formData?.victim_first_name} ${formData?.victim_last_name}` }
              </span>
            </Stack>
            : <SearchableDropdown
            label='Žrtva nasilja'
            searchBy='victim'
            required
            onChange={ (e: any, value: any) => { onInput('victim_id', value.key) } }
            />
         }
          <StyledDatePicker
                disabled={ !isEditable }
                value={ formData?.date !== undefined && formData.date !== null
                  ? new Date(moment(formData.date, 'DD.MM.YYYY').format())
                  : undefined }
                label='Datum pružanja pomoći'
                onSelectDate={ (date: any) => { onInput('date', moment(date).format('DD.MM.YYYY')) } }
     />
          <StyledTextField
          value={ formData?.notes }
          disabled={ !isEditable }
          label='Opis pružene pomoći'
          name='notes'
          multiline rows={ 4 }
          onChange={ (e: any, value: any) => { onInput(e.target.name, value) } }
          />
          <DropdownWithOptionalField
                options={ DropdownOptions.institutionHelpOptions }
                label='Institucija u kojoj je vršena usluga'
                disabled={ !isEditable }
                selectedKey={ formData?.institution }
                showTextFieldOption='other'
                onChange={ (e: any, option: any) => { onInput('institution', option.key ?? option) } }
              />
          <DropdownWithOptionalField
                options={ DropdownOptions.phaseOptions }
                label='Faza postupka'
                disabled={ !isEditable }
                selectedKey={ formData?.phase }
                showTextFieldOption='other'
                onChange={ (e: any, option: any) => { onInput('phase', option.key ?? option) } }
              />
        </Stack>
      </Stack>
      <Stack horizontal horizontalAlign='start' tokens={{ childrenGap: 30 }} >
        <StyledButton
          disabled={
            formData?.victim_id === undefined ||
            formData?.name === undefined ||
            formData?.name?.length === 0 ||
            actionInProgress }
          onClick={ () => {
            !isEditable
              ? setIsEditable(true)
              : onSubmit()
          } }>
          { actionInProgress ? <Spinner/> : (isEditable ? 'Sačuvaj' : 'Izmjeni') }
        </StyledButton>
        <StyledButton
          red
          disabled={ (!isEditable || actionInProgress) || id === undefined }
          onClick={ () => {
            setActionInProgress(true)
            deleteTrustedPersonEntry(id).then(() => {
              setMessage({ type: MessageBarType.success, text: 'Unos obrisan.' })
              setTimeout(() => {
                navigate('/services/trustperson')
              }, 1500)
            }).catch(() => {
              setActionInProgress(false)
              setMessage({ type: MessageBarType.error, text: 'Greška. Pokušajte ponovo.' })
            })
          } }>
          { actionInProgress ? <Spinner /> : 'Obriši' }
        </StyledButton>
      </Stack>
    </Stack>

  )
}
