import React from 'react'
import MainContentContainer from '../../components/containers/MainContentContainer'
import TrustPersonList from '../../components/trustPerson/TrustPersonList'

export default function TrustPersonListView () {
  return (
    <MainContentContainer
        title={ 'Lista unosa za uslugu - Osoba od povjerenja' }
        content={ <TrustPersonList/> }
        />

  )
}
