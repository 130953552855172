import { COLORS } from '../../assets/theme/colors'

export const MainContentContainerStyle = () => ({
  container: {
    // backgroundColor: COLORS.lightGray,
    width: '100%',
    padding: '25px 50px'
  },
  title: {
    height: '50px',
    width: '100%',
    borderBottom: `2px solid ${COLORS.primary}`,
    fontSize: '26px',
    lineHeight: '40px',
    letterSpacing: '1px',
    textAlign: 'left' as const,
    fontWeight: '600',
    color: COLORS.darkerGray,
    animation: 'fadeIn 1s',
    userSelect: 'none' as const
  },
  content: {
    backgroundColor: 'white',
    animation: 'fadeIn 1.5s'

  },
  backButton: {
    root: {
      width: '100px',
      fontSize: '15px',
      backgroundColor: 'transparent',
      border: 'none',
      borderRadius: '5px'
    },
    rootHovered: {
      color: COLORS.primary
    },
    rootPressed: {
      color: COLORS.primary
    }
  }
})
